import { InlineWidget } from "react-calendly";
import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>Desafia't. Supera’t. Prepara’t per guanyar.</strong>
                </p>

                <p>
                    Vols posar-te a prova i viure una experiència real d’entrevista policial com si
                    fos el gran dia?
                    <br />A iOpos t’oferim molt més que una preparació: t’entrenem per destacar.
                </p>

                <p>
                    <strong>🎭 Simulació real amb role play</strong>
                </p>

                <p>
                    Recrearem fidelment el mateix escenari que et trobaràs el dia de l’entrevista
                    oficial.
                    <br />
                    Durant 1h 30min cara a cara amb un preparador expert, viuràs una simulació amb
                    preguntes reals extretes de processos policials actuals, seguida d’una anàlisi
                    profunda i orientació personalitzada.
                </p>

                <ul>
                    <li>📍 45 minuts de simulacre intensiu</li>
                    <li>📍 45 minuts de feedback i preparació específica</li>
                </ul>

                <p>Tot pensat a mida per a tu, en funció del teu perfil i necessitats.</p>

                <p>
                    <strong>🎓 Accés exclusiu a Campus Online</strong>
                </p>

                <p>
                    Per completar la teva preparació, tindràs 30 dies d’accés al nostre campus
                    virtual amb:
                </p>

                <ul>
                    <li>✅ Casos pràctics oficials</li>
                    <li>✅ Explicacions detallades i il·lustrades</li>
                    <li>✅ Recursos per construir una base sòlida</li>
                </ul>

                <p>Tot dissenyat perquè avancis amb seguretat i coneixement.</p>

                <p>
                    <strong>💡 Una metodologia única</strong>
                </p>

                <p>
                    El nostre mètode no és qualsevol mètode. És el que ens ha portat a tenir una de
                    les taxes d’èxit més altes del sector.
                </p>

                <ul>
                    <li>✔️ Entrenament psicològic per reduir l’ansietat anticipatòria</li>
                    <li>✔️ Suport emocional i tècnic</li>
                    <li>✔️ Preparació honesta, natural i 100% personalitzada</li>
                </ul>

                <p>
                    T’ensenyem a dominar la pressió, a mostrar la millor versió de tu mateix i a
                    respondre amb autenticitat i seguretat.
                </p>

                <p>
                    <strong>📅 Toca fer el pas</strong>
                </p>

                <p>
                    No deixis la teva entrevista en mans de la sort.
                    <br />
                    Reserva el teu simulacre avui mateix.
                    <br />
                    Escull el dia i l’hora… i comença a marcar la diferència.
                    <br />
                    T’estem esperant. 🚓💼
                </p>

                <div className="rounded-md shadow-xl p-5 border border-gray-400">
                    <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                        Formador Toni (Agent) - C/ Francesc Vila, 16-17, Sant Cugat del Vallès
                        (Crossfit Lynx)
                    </h1>

                    <h2 className="text-yellow-600 font-montserrat mt-5">
                        Si no veus correctament el calendari fes clic{" "}
                        <a
                            target="_blank"
                            rel="noreferrer noopener"
                            className="font-bold"
                            href="https://calendly.com/iopos-academia-toni/simulacre-preparacio-d-entrevista-1-30-h"
                        >
                            aquí.
                        </a>
                    </h2>

                    <InlineWidget
                        styles={{ width: "100%", height: "920px" }}
                        // className="-mt-10"
                        url="https://calendly.com/iopos-academia-toni/simulacre-preparacio-d-entrevista-1-30-h?hide_gdpr_banner=1"
                    />
                </div>

                <div className="rounded-md shadow-xl p-5 border border-gray-400">
                    <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                        Formador Jordi (Psicòleg) - C/ Martí Codolar, 18 - Hospitalet de Llobregat
                    </h1>

                    <h2 className="text-yellow-600 font-montserrat mt-5">
                        Si no veus correctament el calendari fes clic{" "}
                        <a
                            target="_blank"
                            rel="noreferrer noopener"
                            className="font-bold"
                            href="https://calendly.com/iopos-academia-jordi/simulacre-preparacio-d-entrevista-1-30-h"
                        >
                            aquí.
                        </a>
                    </h2>

                    <InlineWidget
                        styles={{ width: "100%", height: "920px" }}
                        // className="-mt-10"
                        url="https://calendly.com/iopos-academia-jordi/simulacre-preparacio-d-entrevista-1-30-h?hide_gdpr_banner=1"
                    />
                </div>

                <div className="rounded-md shadow-xl p-5 border border-gray-400">
                    <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                        Formador Txema (Agent CME, cursant grau en psicologia) - C/ Martí Codolar,
                        18 - Hospitalet de Llobregat
                    </h1>

                    <h2 className="text-yellow-600 font-montserrat mt-5">
                        Si no veus correctament el calendari fes clic{" "}
                        <a
                            target="_blank"
                            rel="noreferrer noopener"
                            className="font-bold"
                            href="https://calendly.com/iopos-academia-jose-m/simulacre-preparacio-dentrevista-policial-2025-130h"
                        >
                            aquí.
                        </a>
                    </h2>

                    <InlineWidget
                        styles={{ width: "100%", height: "920px" }}
                        // className="-mt-10"
                        url="https://calendly.com/iopos-academia-jose-m/simulacre-preparacio-dentrevista-policial-2025-130h?hide_gdpr_banner=1"
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Domina la teva entrevista policial amb una preparació 100% personalitzada i augmenta les teves possibilitats d'èxit!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;

/*import React from "react";
import ProductTemplate from "./product";

// import NewProductTemplate from "../../components/sections/product-more-info/Item";

import Title from "@components/common/Title";
import Text from "@components/common/Text";

import { InlineWidget } from "react-calendly";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;    

    const Data = () => {
        return (
            <>                
                <div className="container lg:px-0 py-20">
                    <Title>Preparació/Simuacre d'Entrevista (Policia Local 2025 - 90 minuts)</Title>

                    <Text>
                        Vols posar-te a prova amb una simulació/preparació d'entrevista policial amb
                        role play?
                        <br />
                        <br />
                        Recrearem el mateix escenari que el dia de la prova oficial.
                        <br />
                        <br />
                        La nostra preparació és molt personalitzada, amb preguntes extretes de
                        processos policials actuals.
                        <br />
                        <br />
                        Això fa que el nostre mètode sigui únic i el nostre percentatge d'aprovats
                        sigui altíssim.
                        <br />
                        <br />
                        En una entrevista policial estaràs nerviós, assumeix-ho.
                        <br />
                        <br />
                        Des de iOpos t'ensenya a minimitzar l'ansietat anticipatòria.
                        <br />
                        <br />
                        És un procés normal, adaptatiu. Una bona preparació per part del nostre
                        equip t'ajudarà molt.
                        <br />
                        <br />
                        Treballarem tots els aspectes clau que necessitaràs per afrontar-la de la
                        millor manera possible.
                        <br />
                        <br />
                        La metodologia és del tot personalitzada i es posarà èmfasi en les
                        preguntes-respostes més importants en base a cada alumne.
                        <br />
                        <br />
                        En definitiva, t'ajudarem a treballar la teva entrevista personal des d'un
                        punt de vista honest i natural.
                        <br />
                        <br />
                        La preparació consisteix en 1 hora i 30 minuts cara a cara amb un preparador
                        especialitzat que t'analitzarà i et guiarà de la millor manera.
                        <br />
                        <br />
                        45 minuts de simulacre i 45 minuts de preparació.
                        <br />
                        <br />
                        És totalment personalitzada i en base a cada aspirant.
                        <br />
                        <br />
                        Escull dia i hora...
                        <br />
                        <br />
                        T'esperem!
                    </Text>

                    <div id="disponibles"></div>

                    <div className="mt-10 border-t border-b border-gray-300 pt-5 pb-10">
                        <h1 className="font-bold uppercase font-montserrat text-xl text-cpurple">
                        Entrevistes disponibles per Formador i Ubicació
                    </h1>

                        <div className="grid grid-cols-1 gap-10 mt-10">
                            
                            <div className="rounded-md shadow-xl p-5 border border-gray-400 space-y-5">
                                <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                    Formador Jordi Psicólogo - C/ Martí Codolar, 18 - Hospitalet de
                                    Llobregat
                                </h1>

                                <h2 className="text-yellow-600 font-montserrat">
                                    Si no ves correctamente el calendario haz clic{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="font-bold"
                                        href="https://calendly.com/iopos-academia-jordi/simulacre-preparacio-d-entrevista-1-30-h"
                                    >
                                        aquí.
                                    </a>
                                </h2>

                                <InlineWidget
                                    styles={{ width: "100%", height: "920px" }}
                                    url="https://calendly.com/iopos-academia-jordi/simulacre-preparacio-d-entrevista-1-30-h/?hide_gdpr_banner=1"
                                />
                            </div>

                            
                            <div className="rounded-md shadow-xl p-5 border border-gray-400 space-y-5">
                                <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                    Formador Jonathan - C/ Martí Codolar, 18 - Hospitalet de
                                    Llobregat
                                </h1>

                                <h2 className="text-yellow-600 font-montserrat">
                                    Si no ves correctamente el calendario haz clic{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="font-bold"
                                        href="https://calendly.com/iopos-academia-jona/simulacre-preparacio-d-entrevista-1-30-h"
                                    >
                                        aquí.
                                    </a>
                                </h2>

                                <InlineWidget
                                    styles={{ width: "100%", height: "920px" }}
                                    url="https://calendly.com/iopos-academia-jona/simulacre-preparacio-d-entrevista-1-30-h/?hide_gdpr_banner=1"
                                />
                            </div>                                                   
                        </div>

                        <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                            <iframe
                                title={item.title}
                                width="100%"
                                height="600px"
                                src="https://www.youtube.com/embed/nnlG-L9xGFg"
                                frameBorder={"0"}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen={true}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <ProductTemplate item={item}>
                <Data />
            </ProductTemplate>
        </>
    );
};

export default Product;
*/
